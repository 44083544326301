<template>
    <div id="carouselExampleCaptions" class="carousel slide mb-2" data-ride="carousel" data-interval="3000">
      <div class="carousel-inner">
          <div
            :class="(index==0) ? 'carousel-item active':'carousel-item'"
            v-for="(ban,index) in storebanners"
            v-bind:key="index"
            v-bind:style="bannerBgImage(ban)"
          >
          <div class="carousel-caption">
            <div class="banner-text text-white">
              <h2 class="big-title" v-if="ban.banner_alt_text!=null">{{ban.banner_alt_text}}</h2>
            </div>
          </div>
          </div>
      </div>
      <a
        class="carousel-control-prev"
        href="#carouselExampleCaptions"
        role="button"
        data-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a
        class="carousel-control-next"
        href="#carouselExampleCaptions"
        role="button"
        data-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
</template>
<script>
export default {
  name: "PageSlider",
  data() {
    return {
      is_business_model: 0,
      userprofile: null,
      storebanners: null,
    }
  },
  mounted() {
    if (localStorage.getItem("user") != null) {
      this.userprofile = JSON.parse(localStorage.getItem("user"));
    } else {
      this.userprofile = null;
    }
    this.getCityWiseDashboard();
  },
  methods: {
    getCityWiseDashboard() {
      this.loading = true;

      var lat = '23.0421945', lng = '72.5402261';
      if(localStorage.getItem("location_details") != null){
        if(JSON.parse(localStorage.getItem("location_details")).lat != null && JSON.parse(localStorage.getItem("location_details")).lng != null){
            lat = JSON.parse(localStorage.getItem("location_details")).lat;
            lng = JSON.parse(localStorage.getItem("location_details")).lng;
        }
      }

      let bannerbody = {
        user_id: (this.userprofile != null) ? this.userprofile.user_id : "0",
        lat: lat,
        long: lng,
        is_demo_app: localStorage.getItem("is_business_model") == 3 ? 1 : 0,
        is_mobile:0
      };
      this.$store.dispatch("getBanners", bannerbody).then(response => {
        this.loading = false;
        this.storedetails = JSON.parse(JSON.stringify(response.data));
        if(this.storedetails.status == 1) {
          if(localStorage.getItem("is_business_model")  == 3){
            this.storebanners = this.storedetails.data.customer_stores[0].banners;
          } else {
            this.storebanners = this.storedetails.data.banners;
          }
        }
      });
    },
    bannerBgImage(ban) {
      var result = '';
      result = 'background-image: url("' + ban.banner + '");';
      return result;
    },
  }
};
</script>