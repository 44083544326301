<template>
    <Defaultlayout :userprofile="userprofile">
        <PageSlider></PageSlider>
        <div class="universityprogram">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 col-12" style="padding-top: 60px;">
                        <h1 class="page-heading mb-2">Ghar Bazaar Exclusive Deals</h1>
                    </div>
                    <div class="col-md-6 col-12" style="padding-top: 60px;text-align: -webkit-right;">
                        <select class="form-control col-sm-6 col-12" v-model="selectedStore" @change="getStoreBanner()">
                            <option v-for="(store, index) in store_lists" :value="store.store_id" v-bind:key="index">
                                {{ store.store_name }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="modal-body" v-if="loading == true">
                    <div class="container">
                        <p class="text-center">
                            <img src="../../assets/images/loading.gif" />
                        </p>
                    </div>
                </div>
                <div v-if="exclusive_deals" class="row">
                    <div v-for="(item, index) in exclusive_deals" v-bind:key="index" class="col-md-4 mt-2 mb-2">
                        <img @click="openExclusiveModal(item.offer_image, item.offer_title, item.offer_alt_text)" :src="item.offer_image"
                            :title="item.offer_alt_text"
                            style="height: 384px; width: 100%;object-fit: cover; object-position: top;cursor: pointer;" />
                        <div class="text-center"><b>{{ item.offer_title }}</b></div>
                        <div class="modal fade" id="exclusive-deals" ref="exclusive-deals" role="dialog"
                            aria-hidden="true">
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-body">
                                        <div style="font-size: 25px;padding-bottom: 10px;"><b>{{ offer_title }}</b>
                                        </div>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        <img :src="exclusive_image" :title="offer_alt_text" style="width: 100%;" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <!-- <h3>No Records Found</h3> -->
                    <div class="basket-order my-order-tab col-lg-12 mt-4 mt-lg-0">
                        <div class="table-responsive basket-list">
                            <div class="empty-box text-center">
                                <div class="contents">
                                    <h3 style="font-size: 26px;">No Deals Found</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Defaultlayout>
</template>
<script>
import Defaultlayout from "../../layouts/Defaultlayout";
import PageSlider from "../pages/PageSlider";
export default {
    name: "ExclusiveDeals",
    components: {
        Defaultlayout,
        PageSlider,
    },
    data() {
        return {
            userprofile: null,
            exclusive_deals: "",
            store_lists: "",
            selectedStore: null,
            store_exclusive_offer: {
                store_id: "",
                is_active: "",
            },
            global_setting: "",
            exclusive_image: "",
            offer_title: "",
            loading: false,
            offer_alt_text: "",
        };
    },
    mounted() {
        this.global_setting = JSON.parse(localStorage.getItem("global_settings"));
        if (this.global_setting?.is_exclusive_deal_offer == 0) {
            if (window.location.pathname == '/exclusive-deals') {
                this.$router.push("/");
                return;
            }
        }
        if (localStorage.getItem("user") != null) {
            this.userprofile = JSON.parse(localStorage.getItem("user"));
        } else {
            this.userprofile = null;
        }
        this.getStoreList();
    },
    methods: {
        getStoreBanner() {
            this.loading = true;
            this.exclusive_deals = ''
            this.store_exclusive_offer.store_id = this.selectedStore;
            this.store_exclusive_offer.is_active = 1;
            this.$store.dispatch("getStoreExclusiveOffer", this.store_exclusive_offer).then(response => {
                this.loading = false;
                this.exclusive_deals = response.data;
            });
        },

        getStoreList() {
            this.$store.dispatch("getstorelist").then(response => {
                if (response.status == 1) {
                    this.store_lists = response.data
                    this.selectedStore = localStorage.getItem("store_id") ? localStorage.getItem("store_id") : this.store_lists[0].store_id;
                    console.log(this.selectedStore,"exclusive deals store id");
                    // this.selectedStore = this.store_lists[0].store_id;
                    this.getStoreBanner();
                }
            });
        },
        openExclusiveModal(image, title, offer_alt_text) {
            this.exclusive_image = image
            this.offer_title = title
            this.offer_alt_text = offer_alt_text
            window.$("#exclusive-deals").modal("show");
        }
    }

};
</script>